import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField } from '@mui/material';
import { useQuery, useMutation } from '@tanstack/react-query';
import { questionService } from '../APIMethods/questionService';
import LoadingComponent from '../LoadingComponents/LoadingComponent';

const QuestionMappings = (props) => {
	const { questionGroupId } = props;
	const [mappings, setMappings] = useState([]);

	const { data: questionGroupData, isLoading } = useQuery({
		queryKey: ['groupQuestions', questionGroupId],
		queryFn: () => questionService.getGroupQuestions(questionGroupId),
	});

	useEffect(() => {
		// console.log("questionGroupData");
		// console.log(questionGroupData.payload.questions);
		if (questionGroupData) {
			setMappings(questionGroupData.questions.map(q => ({
				question_id: q.question_id,
				correct_next_question_id: q.correct_next_question_id,
				incorrect_next_question_id: q.incorrect_next_question_id
			})));
		}
		console.log(mappings);
	}, [questionGroupData]);


	const updateMutation = useMutation({
		mutationFn: (data) => questionService.updateQuestionMappings(data)
	});

	const handleSave = () => {
		updateMutation.mutate({
			question_group_id: parseInt(questionGroupId),
			question_mappings: mappings
		});
	};

	const handleChange = (questionId, field, value) => {
		setMappings(prev => prev.map(m =>
			m.question_id === questionId
				? { ...m, [field]: value ? parseInt(value) : null }
				: m
		));
	};

	if (isLoading) return <LoadingComponent loadMessage="Loading questions" />;

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
			<h2>{questionGroupData.question_group_name} - Question Mappings</h2>
			<TableContainer>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Question ID</TableCell>
							<TableCell>Question Name</TableCell>
							<TableCell>Correct Next Question</TableCell>
							<TableCell>Incorrect Next Question</TableCell>
						</TableRow>
					</TableHead>
					{/* {question.next_correct_question} */}
					<TableBody>
						{questionGroupData.questions.map((question) => (
							<TableRow key={question.question_id}>
								<TableCell>{question.question_id}</TableCell>
								<TableCell>{question.question_name}</TableCell>
								<TableCell>
									<TextField
										type="number"
										value={mappings.find(m => m.question_id === question.question_id)?.correct_next_question_id || null}
										onChange={(e) => handleChange(question.question_id, 'correct_next_question_id', e.target.value)}
									/>
								</TableCell>
								<TableCell>
									<TextField
										type="number"
										value={mappings.find(m => m.question_id === question.question_id)?.incorrect_next_question_id || null}
										onChange={(e) => handleChange(question.question_id, 'incorrect_next_question_id', e.target.value)}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				variant="contained"
				onClick={handleSave}
				sx={{ mt: 2 }}
				disabled={updateMutation.isLoading}
			>
				Save Changes
			</Button>
		</Paper>
	);
};

export default QuestionMappings;