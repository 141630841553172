import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { questionService } from '../APIMethods/questionService';
import LoadingComponent from '../LoadingComponents/LoadingComponent';

const AdminQuestionConsole = () => {
  const navigate = useNavigate();
  const { data: groups, isLoading } = useQuery({
    queryKey: ['questionGroups'],
    queryFn: questionService.getAllQuestionGroups
  });

  if (isLoading) return <LoadingComponent loadMessage="Loading question groups" />;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
      <h2>Question Groups</h2>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Group ID</TableCell>
              <TableCell>Group Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups?.payload.map((group) => (
              <TableRow key={group.question_group_id}>
                <TableCell>{group.question_group_id}</TableCell>
                <TableCell>{group.question_group_name}</TableCell>
                <TableCell>
                  <Button 
                    variant="contained" 
                    onClick={() => navigate(`/admin/questions/${group.question_group_id}`)}
                  >
                    Edit Questions
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AdminQuestionConsole;