import axios from "axios";

const url = process.env.REACT_APP_API_ADMIN_QUESTION;

export const questionService = {

	getAllQuestionGroups: async () => {
		const response = await fetch(url);
		return response.json();
	},

	getGroupQuestions: async (groupId) => {
		const response = await fetch(url + `/questions?question_group_id=${groupId}`);
		const data = await response.json();
		return data.payload;
	},

	updateQuestionMappings: async (data) => {
		const response = await fetch(url+'/questions', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});
		return response.status === 201;
	}
};