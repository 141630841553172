// components/Reports.jsx
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Stack,
  TextField
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { reportService } from '../APIMethods/reportService'
import LoadingComponent from '../LoadingComponents/LoadingComponent';
import CloseIcon from '@mui/icons-material/Close'; // Add this import


const Reports = () => {
  const queryClient = useQueryClient();
  const [showExpiredAlert, setShowExpiredAlert] = useState(false);
  const [prompt, setPrompt] = useState('');



  // Query for fetching all reports
  const { data: reports, isLoading: isReportsLoading } = useQuery({
    queryKey: ['reports'],
    queryFn: reportService.getAllReports
  });

  // Mutation for generating reports
  const generateReportMutation = useMutation({
    mutationFn: reportService.generateReport,
    onSuccess: () => {
      // Refetch reports after successful generation
      queryClient.invalidateQueries(['reports']); //TODO : not efficient. Change this so that all reports data is not fetched again.
    }
  });

  const handleCancelReport = (testId, documentType) => {
    queryClient.setQueryData(['reports'], (oldData) => {
      return oldData.map(report => {
        if (report.test_id === testId) {
          return {
            ...report,
            // If it's a word report, nullify word link, else nullify pdf link
            word_report_temporary_link: documentType === 'word' ? null : report.word_report_temporary_link,
            pdf_report_temporary_link: documentType === 'pdf' ? null : report.pdf_report_temporary_link
          };
        }
        return report;
      });
    });
  };
  const handleGenerateReport = (testId, documentType) => {
    generateReportMutation.mutate({
      test_id: testId,
      document_type: documentType.toLowerCase(),
      input_prompt : prompt
    });
  };

  const handleDownload = async (url, testId, documentType) => {
    if (isUrlExpired(url)) {
      setShowExpiredAlert(true);
      // Automatically trigger regeneration of the report
      handleGenerateReport(testId, documentType);
      return;
    }
    window.open(url, '_blank');
  };

  const renderReportCell = (report, documentType) => {
    const url = documentType === 'word'
      ? report.word_report_temporary_link
      : report.pdf_report_temporary_link;

    if (generateReportMutation.isLoading) {
      return <LoadingComponent loadMessage="Generating report" />;
    }

    if (!url || isUrlExpired(url)) {
      return (
        <Button
          variant="contained"
          onClick={() => handleGenerateReport(report.test_id, documentType)}
          disabled={generateReportMutation.isLoading}
        >
          Generate
        </Button>
      );
    }

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton
          color="primary"
          onClick={() => handleDownload(url, report.test_id, documentType)}
        >
          <DownloadIcon />
        </IconButton>
        <IconButton
          size="small"
          color="error"
          onClick={() => handleCancelReport(report.test_id, documentType)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
    );
  };
  if (isReportsLoading) {
    return <LoadingComponent loadMessage="Loading reports" />;
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
      <h2>Test Reports</h2>
      <TextField
        
        label="Input Prompt"
        variant="outlined"
        margin="normal"
        sx={{ mb: 2, height :'5%', width: '90%'}}  // adds margin bottom for spacing
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Test ID</TableCell>
              <TableCell>Word Report</TableCell>
              <TableCell>PDF Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <TableRow key={report.test_id}>
                <TableCell>{report.test_id}</TableCell>
                <TableCell>
                  {renderReportCell(report, 'word')}
                </TableCell>
                <TableCell>
                  {renderReportCell(report, 'pdf')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Reports;

// TODO utils/urlHelpers.js
const isUrlExpired = (url) => {
  try {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);

    const expiresSeconds = parseInt(params.get('X-Amz-Expires'));
    const dateStr = params.get('X-Amz-Date');

    if (!expiresSeconds || !dateStr) return true;

    // Convert AWS date format (YYYYMMDDTHHMMSSZ) to milliseconds
    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6);
    const day = dateStr.slice(6, 8);
    const hour = dateStr.slice(9, 11);
    const minute = dateStr.slice(11, 13);
    const second = dateStr.slice(13, 15);

    const signedDate = new Date(Date.UTC(
      parseInt(year),
      parseInt(month) - 1, // JS months are 0-based
      parseInt(day),
      parseInt(hour),
      parseInt(minute),
      parseInt(second)
    ));

    const expirationTime = signedDate.getTime() + (expiresSeconds * 1000);
    return Date.now() > expirationTime;
  } catch (error) {
    console.error('Error parsing URL:', error);
    return true; // If we can't parse the URL, assume it's expired
  }
};